import DateOnly from 'dateonly';
export function formatPageToPost(page) {
  return {
    title: page.pageTitle,
    author: page.author,
    avatar: (page.pageImage && page.pageImage.url) || (page.media[0] && page.media[0].url),
    link: (page.urlSlug[0] === '/' ? '' : '/') + page.urlSlug,
    description: page.pageDescription,
    createdAt: new Date(page.createdAt),
    createdDate: new DateOnly(page.createdDate).toDate(),
  };
}

export function formatPageToTemplate(page) {
  return {
    title: page?.pageTitle,
    redirectUrl: page?.urlSlug,
    previewImage: page.pageImage && page.pageImage.url && { location: page.pageImage.url },
    description: page?.pageDescription,
  };
}
