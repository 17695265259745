import { render, staticRenderFns } from "./BlogPostsList1.vue?vue&type=template&id=52ad781a"
import script from "./BlogPostsList1.vue?vue&type=script&lang=js"
export * from "./BlogPostsList1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogPostCard: require('/codebuild/output/src1947065313/src/client/components/blog/BlogPostList/BlogPostCard.vue').default})
